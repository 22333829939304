<template>
    <div class="back-channel">
		<div class="watermark">
			<div class="zone-title">
				<div class="section foreground">
					<div class="col col-40 col-left">
						<a href="https://www.penguinrandomhouse.com/books/561709/the-back-channel-by-william-j-burns/">
							<h2><span class="hidden">The Back Channel</span></h2>
							<div class="hidden">A Memoir of American Diplomacy and the Case for Its Renewal</div>
							<div class="hidden">William J. Burns</div>
						</a>
					</div>
					<div class="col col-60 col-right">
						<h1><span class="hidden">The Archive</span></h1>
					</div>
				</div>
			</div>
			<div class="zone-1">
				<div class="section foreground">
					<div class="top-section">
						<div class="container">
							<p>Browse an archive of nearly 100 newly declassified cables, memos, and emails from Ambassador Burns’s thirty-three-year career&mdash;a sample of one diplomat’s imperfect efforts to provide ground truths, strategic advice, and&mdash;on occasion&mdash;disciplined dissent.</p>
						</div>
					</div>
					<div class="lower-section">
						<div class="container">
							<div class="chapter-boxes">
								<div class="section foreground">
									<div class="timeline">
										<div class="section chapter-box chapter-box1">
											<div class="inner-box">
												<div class="chapter-info">
													<div class="info-container">
														<h4>Apprenticeship</h4>
														<p>My diplomatic apprenticeship exposed me to the best—and worst—of American statecraft and its practitioners, from the early rituals of my first overseas tour to a junior role in a Reagan White House recovering from the self-inflicted wound
															of the Iran-Contra affair.</p>
													</div>
												</div>
												<div class="document-list">
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1984Amman6594" data-title="1984 Amman 6594, July 16, 1984" data-title-friendly="The Changing Face of Jordanian Politics" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1984Amman6594.pdf"
															data-video="False" data-idnumber="1984Amman6594">
															<div class="col col-80">
																<div class="padded-less"><strong>1984 Amman 6594, July 16, 1984 </strong>The Changing Face of Jordanian Politics</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
												</div>
											</div>
											<div class="date-circle"><span>1982-<br>1989</span></div>
										</div>
										<div class="section chapter-box chapter-box2">
											<div class="inner-box">
												<div class="chapter-info">
													<div class="info-container">
														<h4>The Baker Years</h4>
														<p>“Have you ever seen so many things changing so damn fast?” Secretary Baker asked. “This sure is quite a time. I bet you won’t see anything like it for as long as you stay in the Foreign Service.” He was right.</p>
													</div>
												</div>
												<div class="document-list">
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1990MemotoDepSec" data-title="Memo to Deputy Secretary Eagleburger, April 10, 1990" data-title-friendly="Deepening U.S.-East European Relations" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1990MemotoDepSec.pdf"
															data-video="False" data-idnumber="1990MemotoDepSec">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Deputy Secretary Eagleburger, April 10, 1990 </strong>Deepening U.S.-East European Relations</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1990MemotoKimmitt1" data-title="Memo to Under Secretary Kimmitt, August 04, 1990" data-title-friendly="Kuwait: The First Post-Cold War Crisis" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1990MemotoKimmitt1.pdf"
															data-video="False" data-idnumber="1990MemotoKimmitt1">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Under Secretary Kimmitt, August 04, 1990 </strong>Kuwait: The First Post-Cold War Crisis</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1990MemotoKimmitt2" data-title="Memo to Kimmitt, August 20, 1990" data-title-friendly="Containing Saddam: Diplomatic Options" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1990MemotoKimmitt2.pdf"
															data-video="False" data-idnumber="1990MemotoKimmitt2">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Kimmitt, August 20, 1990 </strong>Containing Saddam: Diplomatic Options</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1990Riyadh2457" data-title="1990 Riyadh 2457, November 20, 1990" data-title-friendly="Reflections on Post-Crisis Security Arrangements in the Persian Gulf" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1990Riyadh2457.pdf"
															data-video="False" data-idnumber="1990Riyadh2457">
															<div class="col col-80">
																<div class="padded-less"><strong>1990 Riyadh 2457, November 20, 1990 </strong>Reflections on Post-Crisis Security Arrangements in the Persian Gulf</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1992MemotoBaker" data-title="Memo to Secretary Baker, April 30, 1992" data-title-friendly="Foreign Policy in the Second Bush Administration: An Overview" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1992MemotoBaker.pdf"
															data-video="False" data-idnumber="1992MemotoBaker">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Secretary Baker, April 30, 1992 </strong>Foreign Policy in the Second Bush Administration: An Overview</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1993MemotoChristopher" data-title="Memo to Secretary of State-Designate Christopher, January 05, 1993" data-title-friendly="Parting Thoughts: U.S. Foreign Policy in the Years Ahead" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1993MemotoChristopher.pdf"
															data-video="True" data-idnumber="1993MemotoChristopher">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Secretary of State-Designate Christopher, January 05, 1993 </strong>Parting Thoughts: U.S. Foreign Policy in the Years Ahead</div>
															</div>
															<div class="col col-15">
																
															</div>
														</a>
													</div>
												</div>
											</div>
											<div class="date-circle"><span>1989-<br>1993</span></div>
										</div>
										<div class="section chapter-box chapter-box3">
											<div class="inner-box">
												<div class="chapter-info">
													<div class="info-container">
														<h4>Yeltsin’s Russia</h4>
														<p>To understand the grievances, mistrust, and smoldering aggressiveness of Putin’s Russia, you first have to appreciate the sense of humiliation, wounded pride, and disorder that was often inescapable in Yeltsin’s.</p>
													</div>
												</div>
												<div class="document-list">
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1994Moscow27483" data-title="1994 Moscow 27483, September 22, 1994" data-title-friendly="Yeltsin and Russia on the Eve of the Summit" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1994Moscow27483.pdf"
															data-video="False" data-idnumber="1994Moscow27483">
															<div class="col col-80">
																<div class="padded-less"><strong>1994 Moscow 27483, September 22, 1994 </strong>Yeltsin and Russia on the Eve of the Summit</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1994Moscow35186" data-title="1994 Moscow 35186, December 06, 1994" data-title-friendly="Russia and NATO" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1994Moscow35186.pdf"
															data-video="False" data-idnumber="1994Moscow35186">
															<div class="col col-80">
																<div class="padded-less"><strong>1994 Moscow 35186, December 06, 1994 </strong>Russia and NATO</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1994Moscow35565" data-title="1994 Moscow 35565, December 09, 1994" data-title-friendly="Russia on the Eve of the Vice President's Visit" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1994Moscow35565.pdf"
															data-video="False" data-idnumber="1994Moscow35565">
															<div class="col col-80">
																<div class="padded-less"><strong>1994 Moscow 35565, December 09, 1994 </strong>Russia on the Eve of the Vice President's Visit</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1995Moscow883" data-title="1995 Moscow 883, January 11, 1995" data-title-friendly="Sifting Through the Wreckage: Chechnya and Russia's Future" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1995Moscow883.pdf"
															data-video="True" data-idnumber="1995Moscow883">
															<div class="col col-80">
																<div class="padded-less"><strong>1995 Moscow 883, January 11, 1995 </strong>Sifting Through the Wreckage: Chechnya and Russia's Future</div>
															</div>
															<div class="col col-15">
																
															</div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1995Moscow5788" data-title="1995 Moscow 5788, February 22, 1995" data-title-friendly="Yeltsin and Russia Totter On" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1995Moscow5788.pdf"
															data-video="False" data-idnumber="1995Moscow5788">
															<div class="col col-80">
																<div class="padded-less"><strong>1995 Moscow 5788, February 22, 1995 </strong>Yeltsin and Russia Totter On</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1995Moscow6176" data-title="1995 Moscow 6176, February 24, 1995" data-title-friendly="Ambassador's Meeting on Chechnya with Disaster Relief Expert" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1995Moscow6176.pdf"
															data-video="False" data-idnumber="1995Moscow6176">
															<div class="col col-80">
																<div class="padded-less"><strong>1995 Moscow 6176, February 24, 1995 </strong>Ambassador's Meeting on Chechnya with Disaster Relief Expert</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1995Moscow19971" data-title="1995 Moscow 19971, June 26, 1995" data-title-friendly="Coping with Uncertainty: Russia on the Eve of the Vice President's Visit" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1995Moscow19971.pdf"
															data-video="False" data-idnumber="1995Moscow19971">
															<div class="col col-80">
																<div class="padded-less"><strong>1995 Moscow 19971, June 26, 1995 </strong>Coping with Uncertainty: Russia on the Eve of the Vice President's Visit</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1995Moscow19896" data-title="1995 Moscow 19896, June 26, 1995" data-title-friendly="Ingush and Chechen Views on the Fred Cuny Case" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1995Moscow19896.pdf"
															data-video="False" data-idnumber="1995Moscow19896">
															<div class="col col-80">
																<div class="padded-less"><strong>1995 Moscow 19896, June 26, 1995 </strong>Ingush and Chechen Views on the Fred Cuny Case</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1995Moscow26910" data-title="1995 Moscow 26910, August 23, 1995" data-title-friendly="Cuny Case" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1995Moscow26910.pdf"
															data-video="False" data-idnumber="1995Moscow26910">
															<div class="col col-80">
																<div class="padded-less"><strong>1995 Moscow 26910, August 23, 1995 </strong>Cuny Case</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1995Moscow32066" data-title="1995 Moscow 32066, October 05, 1995" data-title-friendly="Thoughts on the Eve of the VP's Meeting" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1995Moscow32066.pdf"
															data-video="False" data-idnumber="1995Moscow32066">
															<div class="col col-80">
																<div class="padded-less"><strong>1995 Moscow 32066, October 05, 1995 </strong>Thoughts on the Eve of the VP's Meeting</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1995Moscow39005" data-title="1995 Moscow 39005, December 09, 1995" data-title-friendly="Keeping a Sense of Perspective: The Duma Elections, Russia's Future and U.S. Policy" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1995Moscow39005.pdf"
															data-video="False" data-idnumber="1995Moscow39005">
															<div class="col col-80">
																<div class="padded-less"><strong>1995 Moscow 39005, December 09, 1995 </strong>Keeping a Sense of Perspective: The Duma Elections, Russia's Future and U.S. Policy</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
												</div>
											</div>
											<div class="date-circle"><span>1993-<br>1996</span></div>
										</div>
										<div class="section chapter-box chapter-box4">
											<div class="inner-box">
												<div class="chapter-info">
													<div class="info-container">
														<h4>Jordan’s Moment of Transition</h4>
														<p>Soon after the death of King Hussein, I cabled Washington: “We have a strong and continuing stake in a stable Jordanian partner at the geographic and political center of the Middle East. If we didn’t have such a partner, we’d have to invent
															one.”</p>
													</div>
												</div>
												<div class="document-list">
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1998Amman9928" data-title="1998 Amman 9928, November 05, 1998" data-title-friendly="Your Visit to Jordan" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1998Amman9928.pdf"
															data-video="False" data-idnumber="1998Amman9928">
															<div class="col col-80">
																<div class="padded-less"><strong>1998 Amman 9928, November 05, 1998 </strong>Your Visit to Jordan</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1999Amman615" data-title="1999 Amman 615, January 26, 1999" data-title-friendly="A Poignant Farewell" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1999Amman615.pdf"
															data-video="False" data-idnumber="1999Amman615">
															<div class="col col-80">
																<div class="padded-less"><strong>1999 Amman 615, January 26, 1999 </strong>A Poignant Farewell</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1999Amman1059" data-title="1999 Amman 1059, February 07, 1999" data-title-friendly="King Hussein's Legacy and Jordan's Future" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1999Amman1059.pdf"
															data-video="True" data-idnumber="1999Amman1059">
															<div class="col col-80">
																<div class="padded-less"><strong>1999 Amman 1059, February 07, 1999 </strong>King Hussein's Legacy and Jordan's Future</div>
															</div>
															<div class="col col-15">
																
															</div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1999Amman1588" data-title="1999 Amman 1588, February 26, 1999" data-title-friendly="Jordan in Transition" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1999Amman1588.pdf"
															data-video="False" data-idnumber="1999Amman1588">
															<div class="col col-80">
																<div class="padded-less"><strong>1999 Amman 1588, February 26, 1999 </strong>Jordan in Transition</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1999Amman3867" data-title="1999 Amman 3867, May 10, 1999" data-title-friendly="A Young Man in a Hurry" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1999Amman3867.pdf"
															data-video="False" data-idnumber="1999Amman3867">
															<div class="col col-80">
																<div class="padded-less"><strong>1999 Amman 3867, May 10, 1999 </strong>A Young Man in a Hurry</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id1999Amman9752" data-title="1999 Amman 9752, December 17, 1999" data-title-friendly="The Honeymoon's Over: Abdullah, Jordan, and the Challenges Ahead" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/1999Amman9752.pdf"
															data-video="False" data-idnumber="1999Amman9752">
															<div class="col col-80">
																<div class="padded-less"><strong>1999 Amman 9752, December 17, 1999 </strong>The Honeymoon's Over: Abdullah, Jordan, and the Challenges Ahead</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2000Amman698" data-title="2000 Amman 698, February 08, 2000" data-title-friendly="Keeping a Sense of Perspective About King Abdullah's First Year" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2000Amman698.pdf"
															data-video="False" data-idnumber="2000Amman698">
															<div class="col col-80">
																<div class="padded-less"><strong>2000 Amman 698, February 08, 2000 </strong>Keeping a Sense of Perspective About King Abdullah's First Year</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2000Amman1909" data-title="2000 Amman 1909, April 12, 2000" data-title-friendly="Political Drift in Jordan" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2000Amman1909.pdf"
															data-video="False" data-idnumber="2000Amman1909">
															<div class="col col-80">
																<div class="padded-less"><strong>2000 Amman 1909, April 12, 2000 </strong>Political Drift in Jordan</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2000Amman2370" data-title="2000 Amman 2370, May 03, 2000" data-title-friendly="King Abdullah Deeply Disappointed by Uncertainty About FTA, Worried About Months Ahead" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2000Amman2370.pdf"
															data-video="False" data-idnumber="2000Amman2370">
															<div class="col col-80">
																<div class="padded-less"><strong>2000 Amman 2370, May 03, 2000 </strong>King Abdullah Deeply Disappointed by Uncertainty About FTA, Worried About Months Ahead</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2000Amman5155" data-title="2000 Amman 5155, September 18, 2000" data-title-friendly="September 18 Conversation with King Abdullah" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2000Amman5155.pdf"
															data-video="False" data-idnumber="2000Amman5155">
															<div class="col col-80">
																<div class="padded-less"><strong>2000 Amman 5155, September 18, 2000 </strong>September 18 Conversation with King Abdullah</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2000Amman5743" data-title="2000 Amman 5743, October 15, 2000" data-title-friendly="The Tragedy Across the River and Jordan's Uncertain Future" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2000Amman5743.pdf"
															data-video="False" data-idnumber="2000Amman5743">
															<div class="col col-80">
																<div class="padded-less"><strong>2000 Amman 5743, October 15, 2000 </strong>The Tragedy Across the River and Jordan's Uncertain Future</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2000Amman6760" data-title="2000 Amman 6760, December 05, 2000" data-title-friendly="Peace Process: Relaunching American Diplomacy" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2000Amman6760.pdf"
															data-video="True" data-idnumber="2000Amman6760">
															<div class="col col-80">
																<div class="padded-less"><strong>2000 Amman 6760, December 05, 2000 </strong>Peace Process: Relaunching American Diplomacy</div>
															</div>
															<div class="col col-15">
																
															</div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2001Amman336" data-title="2001 Amman 336, January 22, 2001" data-title-friendly="Abdullah Faces a Troubled New Year" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2001Amman336.pdf"
															data-video="False" data-idnumber="2001Amman336">
															<div class="col col-80">
																<div class="padded-less"><strong>2001 Amman 336, January 22, 2001 </strong>Abdullah Faces a Troubled New Year</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2001Amman1658" data-title="2001 Amman 1658, April 02, 2001" data-title-friendly="King Abdullah's Visit to Washington" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2001Amman1658.pdf"
															data-video="False" data-idnumber="2001Amman1658">
															<div class="col col-80">
																<div class="padded-less"><strong>2001 Amman 1658, April 02, 2001 </strong>King Abdullah's Visit to Washington</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
												</div>
											</div>
											<div class="date-circle"><span>1998-<br>2001</span></div>
										</div>
										<div class="section chapter-box chapter-box5">
											<div class="inner-box">
												<div class="chapter-info">
													<div class="info-container long-title">
														<h4>Age of Terror</h4>
														<p>After the pain and surprise of 9/11, we cast aside the Bush 41 administration’s unique mix of caution and daring, opting instead for a disastrous mix of militancy and hubris—compounding regional dysfunctions, undercutting our influence,
															and fumbling an historic opportunity to reset America’s role in the world.</p>
													</div>
												</div>
												<div class="document-list">
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2001PaperforPowell" data-title="Paper for Secretary Powell from Bureau of Near Eastern Affairs and the Policy Planning Staff, August 30, 2001" data-title-friendly="Strategies for Preserving U.S. Political Capital in the Middle East"
															href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2001PaperforPowell.pdf" data-video="False" data-idnumber="2001PaperforPowell">
															<div class="col col-80">
																<div class="padded-less"><strong>Paper for Secretary Powell from Bureau of Near Eastern Affairs and the Policy Planning Staff, August 30, 2001 </strong>Strategies for Preserving U.S. Political Capital in the Middle East</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2001MemotoDepSec" data-title="Memo to Deputy Secretary Armitage, November 19, 2001" data-title-friendly="Deputies Committee Meeting on Iraq" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2001MemotoDepSec.pdf"
															data-video="False" data-idnumber="2001MemotoDepSec">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Deputy Secretary Armitage, November 19, 2001 </strong>Deputies Committee Meeting on Iraq</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2002MemotoPowell1" data-title="Memo to Powell, February 14, 2002" data-title-friendly="Regional Concerns Regarding Regime Change in Iraq" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2002MemotoPowell1.pdf"
															data-video="False" data-idnumber="2002MemotoPowell1">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Powell, February 14, 2002 </strong>Regional Concerns Regarding Regime Change in Iraq</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2002EmailtoPowell1" data-title="Email to Powell, April 01, 2002" data-title-friendly="Next Steps on Middle East" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2002EmailtoPowell1.pdf"
															data-video="False" data-idnumber="2002EmailtoPowell1">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Powell, April 01, 2002 </strong>Next Steps on Middle East</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2002NotetoPowell" data-title="Note to Powell, April 20, 2002" data-title-friendly="Impressions from Jenin" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2002NotetoPowell.pdf"
															data-video="False" data-idnumber="2002NotetoPowell">
															<div class="col col-80">
																<div class="padded-less"><strong>Note to Powell, April 20, 2002 </strong>Impressions from Jenin</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2002MemotoPowell3" data-title="Memo to Powell, June 11, 2002" data-title-friendly="Principals Meeting on Middle East" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2002MemotoPowell3.pdf"
															data-video="False" data-idnumber="2002MemotoPowell3">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Powell, June 11, 2002 </strong>Principals Meeting on Middle East</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2002MemotoPowell4" data-title="Memo to Powell, June 25, 2002" data-title-friendly="President's Speech: Short-term Follow-up" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2002MemotoPowell4.pdf"
															data-video="False" data-idnumber="2002MemotoPowell4">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Powell, June 25, 2002 </strong>President's Speech: Short-term Follow-up</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2002MemotoPowell5" data-title="Memo to Powell, July 22, 2002" data-title-friendly="Role of the External Iraqi Opposition" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2002MemotoPowell5.pdf"
															data-video="False" data-idnumber="2002MemotoPowell5">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Powell, July 22, 2002 </strong>Role of the External Iraqi Opposition</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2002MemotoPowell6" data-title="Memo to Powell, July 29, 2002" data-title-friendly="Iraq: The Perfect Storm" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2002MemotoPowell6.pdf"
															data-video="True" data-idnumber="2002MemotoPowell6">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Powell, July 29, 2002 </strong>Iraq: The Perfect Storm</div>
															</div>
															<div class="col col-15">
																
															</div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2002EmailtoPowell3" data-title="Email to Powell, August 16, 2002" data-title-friendly="Iraq and President's UNGA Speech" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2002EmailtoPowell3.pdf"
															data-video="False" data-idnumber="2002EmailtoPowell3">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Powell, August 16, 2002 </strong>Iraq and President's UNGA Speech</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2002MemotoDepSec" data-title="Memo to Armitage, September 30, 2002" data-title-friendly="Planning the Future of Iraq: What to Watch Out For" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2002MemotoDepSec.pdf"
															data-video="False" data-idnumber="2002MemotoDepSec">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Armitage, September 30, 2002 </strong>Planning the Future of Iraq: What to Watch Out For</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2002Riyadh06674" data-title="2002 Riyadh 06674, October 21, 2002" data-title-friendly="Talks in Egypt and Jordan" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2002Riyadh06674.pdf"
															data-video="False" data-idnumber="2002Riyadh06674">
															<div class="col col-80">
																<div class="padded-less"><strong>2002 Riyadh 06674, October 21, 2002 </strong>Talks in Egypt and Jordan</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2002MemotoPowell7" data-title="Memo to Powell, October 22, 2002" data-title-friendly="Meetings in Damascus and Beirut" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2002MemotoPowell7.pdf"
															data-video="False" data-idnumber="2002MemotoPowell7">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Powell, October 22, 2002 </strong>Meetings in Damascus and Beirut</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2003MemotoPowell1" data-title="Memo to Powell, January 16, 2003" data-title-friendly="Today's Iraq PC" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2003MemotoPowell1.pdf"
															data-video="False" data-idnumber="2003MemotoPowell1">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Powell, January 16, 2003 </strong>Today's Iraq PC</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2003Amman00467" data-title="2003 Amman 00467, January 22, 2003" data-title-friendly="Meetings in Bahrain and UAE, January 21-22" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2003Amman00467.pdf"
															data-video="False" data-idnumber="2003Amman00467">
															<div class="col col-80">
																<div class="padded-less"><strong>2003 Amman 00467, January 22, 2003 </strong>Meetings in Bahrain and UAE, January 21-22</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2003MemotoPowell2" data-title="Memo to Powell, February 12, 2003" data-title-friendly="Time for Tough Choices on Post-Conflict Iraq" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2003MemotoPowell2.pdf"
															data-video="False" data-idnumber="2003MemotoPowell2">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Powell, February 12, 2003 </strong>Time for Tough Choices on Post-Conflict Iraq</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2003MemotoPowell3" data-title="Memo to Powell, March 11, 2003" data-title-friendly="Read-Out of Libya Meetings" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2003MemotoPowell3.pdf"
															data-video="False" data-idnumber="2003MemotoPowell3">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Powell, March 11, 2003 </strong>Read-Out of Libya Meetings</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2003EmailtoPowell1" data-title="Email to Powell, March 22, 2003" data-title-friendly="Middle East: Update, 3/22 (1500)" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2003EmailtoPowell1.pdf"
															data-video="False" data-idnumber="2003EmailtoPowell1">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Powell, March 22, 2003 </strong>Middle East: Update, 3/22 (1500)</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2003EmailtoPowell2" data-title="Email to Powell, July 11, 2003" data-title-friendly="Rethinking Our Iraq Strategy" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2003EmailtoPowell2.pdf"
															data-video="False" data-idnumber="2003EmailtoPowell2">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Powell, July 11, 2003 </strong>Rethinking Our Iraq Strategy</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2004MemotoPowell" data-title="Memo to Powell, January 02, 2004" data-title-friendly="The Year Ahead: NEA's Ten Key Priorities for 2004" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2004MemotoPowell.pdf"
															data-video="False" data-idnumber="2004MemotoPowell">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Powell, January 02, 2004 </strong>The Year Ahead: NEA's Ten Key Priorities for 2004</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2004EmailtoPowell1" data-title="Email to Powell, February 06, 2004" data-title-friendly="Libya Talks, February 6" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2004EmailtoPowell1.pdf"
															data-video="False" data-idnumber="2004EmailtoPowell1">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Powell, February 06, 2004 </strong>Libya Talks, February 6</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2004EmailtoPowell2" data-title="Email to Powell, March 24, 2004" data-title-friendly="Note from Bill Burns: Libya, March 23" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2004EmailtoPowell2.pdf"
															data-video="True" data-idnumber="2004EmailtoPowell2">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Powell, March 24, 2004 </strong>Note from Bill Burns: Libya, March 23</div>
															</div>
															<div class="col col-15">
																
															</div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2004MemotoRice" data-title="Memo to Secretary of State-designate Rice, December 06, 2004" data-title-friendly="Policy Paper for the Bureau of Near Eastern Affairs" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2004MemotoRice.pdf"
															data-video="False" data-idnumber="2004MemotoRice">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Secretary of State-designate Rice, December 06, 2004 </strong>Policy Paper for the Bureau of Near Eastern Affairs</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2005MemotoRice" data-title="Memo to Rice, February 16, 2005" data-title-friendly="Hariri's Murder and the Way Ahead" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2005MemotoRice.pdf"
															data-video="False" data-idnumber="2005MemotoRice">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Rice, February 16, 2005 </strong>Hariri's Murder and the Way Ahead</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
												</div>
											</div>
											<div class="date-circle"><span>2001-<br>2005</span></div>
										</div>
										<div class="section chapter-box chapter-box6">
											<div class="inner-box">
												<div class="chapter-info">
													<div class="info-container">
														<h4>Putin’s Russia</h4>
														<p>This was not the Russia I had left a decade earlier, flat on its back and in strategic retreat. Putin was determined to show that he was making Russia great again, and we better get used to it.</p>
													</div>
												</div>
												<div class="document-list">
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2006Moscow1925" data-title="2006 Moscow 1925, February 28, 2006" data-title-friendly="Lavrov's Visit and Strategic Engagement with Russia" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2006Moscow1925.pdf"
															data-video="False" data-idnumber="2006Moscow1925">
															<div class="col col-80">
																<div class="padded-less"><strong>2006 Moscow 1925, February 28, 2006 </strong>Lavrov's Visit and Strategic Engagement with Russia</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2006EmailtoRice" data-title="Email to Secretary Rice, April 11, 2006" data-title-friendly="Note for the Secretary from Bill Burns" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2006EmailtoRice.pdf"
															data-video="False" data-idnumber="2006EmailtoRice">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Secretary Rice, April 11, 2006 </strong>Note for the Secretary from Bill Burns</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2006Moscow6759" data-title="2006 Moscow 6759, June 26, 2006" data-title-friendly="Your Visit to Moscow" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2006Moscow6759.pdf"
															data-video="False" data-idnumber="2006Moscow6759">
															<div class="col col-80">
																<div class="padded-less"><strong>2006 Moscow 6759, June 26, 2006 </strong>Your Visit to Moscow</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2006MemoforRecord" data-title="Memo for the Record, October 22, 2006" data-title-friendly="A Birthday Dinner with Putin's Politburo" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2006MemoforRecord.pdf"
															data-video="False" data-idnumber="2006MemoforRecord">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo for the Record, October 22, 2006 </strong>A Birthday Dinner with Putin's Politburo</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2006Moscow11939" data-title="2006 Moscow 11939, October 25, 2006" data-title-friendly="Your Visit to Moscow" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2006Moscow11939.pdf"
															data-video="False" data-idnumber="2006Moscow11939">
															<div class="col col-80">
																<div class="padded-less"><strong>2006 Moscow 11939, October 25, 2006 </strong>Your Visit to Moscow</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2007EmailtoRice1" data-title="Email to Rice, January 31, 2007" data-title-friendly="Thoughts on Lavrov Visit" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2007EmailtoRice1.pdf"
															data-video="False" data-idnumber="2007EmailtoRice1">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Rice, January 31, 2007 </strong>Thoughts on Lavrov Visit</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2007EmailtoRice2" data-title="Email to Rice, February 16, 2007" data-title-friendly="Thoughts on Munich and Russian Government Reshuffle" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2007EmailtoRice2.pdf"
															data-video="False" data-idnumber="2007EmailtoRice2">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Rice, February 16, 2007 </strong>Thoughts on Munich and Russian Government Reshuffle</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2007Moscow2588" data-title="2007 Moscow 2588, June 01, 2007" data-title-friendly="Your Meeting with Putin at G-8" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2007Moscow2588.pdf"
															data-video="False" data-idnumber="2007Moscow2588">
															<div class="col col-80">
																<div class="padded-less"><strong>2007 Moscow 2588, June 01, 2007 </strong>Your Meeting with Putin at G-8</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2007Moscow2776" data-title="2007 Moscow 2776, June 11, 2007" data-title-friendly="June 9-10 Conversations with Putin and His Senior Advisors" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2007Moscow2776.pdf"
															data-video="False" data-idnumber="2007Moscow2776">
															<div class="col col-80">
																<div class="padded-less"><strong>2007 Moscow 2776, June 11, 2007 </strong>June 9-10 Conversations with Putin and His Senior Advisors</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2007Moscow3095" data-title="2007 Moscow 3095, June 26, 2007" data-title-friendly="Putin's Visit to Kennebunkport" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2007Moscow3095.pdf"
															data-video="False" data-idnumber="2007Moscow3095">
															<div class="col col-80">
																<div class="padded-less"><strong>2007 Moscow 3095, June 26, 2007 </strong>Putin's Visit to Kennebunkport</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2008EmailtoRice1" data-title="Email to Rice, February 08, 2008" data-title-friendly="Russia Strategy" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2008EmailtoRice1.pdf"
															data-video="True" data-idnumber="2008EmailtoRice1">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Rice, February 08, 2008 </strong>Russia Strategy</div>
															</div>
															<div class="col col-15">
																
															</div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2008Moscow886" data-title="2008 Moscow 886, April 01, 2008" data-title-friendly="Your Visit to Sochi" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2008Moscow886.pdf"
															data-video="False" data-idnumber="2008Moscow886">
															<div class="col col-80">
																<div class="padded-less"><strong>2008 Moscow 886, April 01, 2008 </strong>Your Visit to Sochi</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
												</div>
											</div>
											<div class="date-circle"><span>2005-<br>2008</span></div>
										</div>
										<div class="section chapter-box chapter-box7">
											<div class="inner-box">
												<div class="chapter-info">
													<div class="info-container">
														<h4> Obama’s Long Game</h4>
														<p>Obama took office aiming to rebalance American policy and tools—molding an emerging international order, realigning relationships with major powers like China, India, and Russia, and reinvesting in diplomacy to tackle international threats.</p>
													</div>
												</div>
												<div class="document-list">
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2008MemotoRice2" data-title="Memo to Secretary Rice, August 27, 2008" data-title-friendly="India Civil Nuclear Initiative" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2008MemotoRice2.pdf"
															data-video="False" data-idnumber="2008MemotoRice2">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Secretary Rice, August 27, 2008 </strong>India Civil Nuclear Initiative</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2009MemotoClinton2" data-title="Memo to Secretary Clinton, February 13, 2009" data-title-friendly="February 11-12 Meetings in Moscow" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2009MemotoClinton2.pdf"
															data-video="False" data-idnumber="2009MemotoClinton2">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Secretary Clinton, February 13, 2009 </strong>February 11-12 Meetings in Moscow</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2009MemotoClinton3" data-title="Memo to Clinton, March 20, 2009" data-title-friendly="A New Partnership with India" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2009MemotoClinton3.pdf"
															data-video="False" data-idnumber="2009MemotoClinton3">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Clinton, March 20, 2009 </strong>A New Partnership with India</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2009MemotoClinton4" data-title="Memo to Clinton, June 12, 2009" data-title-friendly="Seizing the Moment with India" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2009MemotoClinton4.pdf"
															data-video="False" data-idnumber="2009MemotoClinton4">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Clinton, June 12, 2009 </strong>Seizing the Moment with India</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2009EmailtoClinton" data-title="Email to Clinton, September 07, 2009" data-title-friendly="Note for the Secretary: Missile Defense" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2009EmailtoClinton.pdf"
															data-video="False" data-idnumber="2009EmailtoClinton">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Clinton, September 07, 2009 </strong>Note for the Secretary: Missile Defense</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2011EmailtoClinton4" data-title="Email to Clinton, December 05, 2011" data-title-friendly="Monday Update" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2011EmailtoClinton4.pdf"
															data-video="False" data-idnumber="2011EmailtoClinton4">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Clinton, December 05, 2011 </strong>Monday Update</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
												</div>
											</div>
											<div class="date-circle"><span>2009-<br>2011</span></div>
										</div>
										<div class="section chapter-box chapter-box8">
											<div class="inner-box">
												<div class="chapter-info">
													<div class="info-container">
														<h4>The Arab Spring</h4>
														<p>Obama’s broad strategy was to gradually break the Middle East’s decades-long hold on American foreign policy. But as the revolutionary drama of the Arab Spring broke out, he soon found himself inexorably pulled back to the same crisis-driven
															focus that plagued his predecessors—and that he had hoped so much to escape.</p>
													</div>
												</div>
												<div class="document-list">
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2010EmailtoClinton" data-title="Email to Secretary Clinton, February 17, 2010" data-title-friendly="Note for the Secretary from Bill Burns: Meetings in Damascus, February 17" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2010EmailtoClinton.pdf"
															data-video="False" data-idnumber="2010EmailtoClinton">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Secretary Clinton, February 17, 2010 </strong>Note for the Secretary from Bill Burns: Meetings in Damascus, February 17</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2011EmailtoClinton1" data-title="Email to Clinton, February 22, 2011" data-title-friendly="Note for the Secretary from Bill Burns: Cairo, February 21-22" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2011EmailtoClinton1.pdf"
															data-video="True" data-idnumber="2011EmailtoClinton1">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Clinton, February 22, 2011 </strong>Note for the Secretary from Bill Burns: Cairo, February 21-22</div>
															</div>
															<div class="col col-15">
																
															</div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2011EmailtoClinton2" data-title="Email to Clinton, February 23, 2011" data-title-friendly="Note for the Secretary from Bill Burns: Tunis, February 23" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2011EmailtoClinton2.pdf"
															data-video="False" data-idnumber="2011EmailtoClinton2">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Clinton, February 23, 2011 </strong>Note for the Secretary from Bill Burns: Tunis, February 23</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2011EmailtoClinton3" data-title="Email to Clinton, June 30, 2011" data-title-friendly="Note for the Secretary from Bill Burns: Tunis and Cairo, June 27-30" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2011EmailtoClinton3.pdf"
															data-video="False" data-idnumber="2011EmailtoClinton3">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Clinton, June 30, 2011 </strong>Note for the Secretary from Bill Burns: Tunis and Cairo, June 27-30</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2011EmailtoClinton5" data-title="Email to Clinton, January 12, 2012" data-title-friendly="Note for the Secretary from Bill Burns: Egypt, January 10-12" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2011EmailtoClinton5.pdf"
															data-video="False" data-idnumber="2011EmailtoClinton5">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Clinton, January 12, 2012 </strong>Note for the Secretary from Bill Burns: Egypt, January 10-12</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2014EmailtoKerry" data-title="Email to Secretary Kerry, April 25, 2014" data-title-friendly="Tripoli, April 23-24" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2014EmailtoKerry.pdf"
															data-video="False" data-idnumber="2014EmailtoKerry">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Secretary Kerry, April 25, 2014 </strong>Tripoli, April 23-24</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
												</div>
											</div>
											<div class="date-circle"><span>2011-<br>2014</span></div>
										</div>
										<div class="section chapter-box chapter-box9">
											<div class="inner-box">
												<div class="chapter-info">
													<div class="info-container">
														<h4>Iran and the Bomb</h4>
														<p>The back channel with Iran gave us a chance to prove that smart, hard-nosed diplomacy could resolve one of the world’s most combustible challenges. President Obama’s guidance was straight-forward: “Don’t screw it up.”</p>
													</div>
												</div>
												<div class="document-list">
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2008MemotoRice1" data-title="Memo to Secretary Rice, May 27, 2008" data-title-friendly="Regaining the Strategic Initiative on Iran" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2008MemotoRice1.pdf"
															data-video="True" data-idnumber="2008MemotoRice1">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Secretary Rice, May 27, 2008 </strong>Regaining the Strategic Initiative on Iran</div>
															</div>
															<div class="col col-15">
																
															</div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2008EmailtoRice2" data-title="Email to Rice, July 19, 2008" data-title-friendly="Note for the Secretary from Bill Burns: Meeting with the Iranians, July 19" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2008EmailtoRice2.pdf"
															data-video="False" data-idnumber="2008EmailtoRice2">
															<div class="col col-80">
																<div class="padded-less"><strong>Email to Rice, July 19, 2008 </strong>Note for the Secretary from Bill Burns: Meeting with the Iranians, July 19</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
													<div class="doc-container cols divider divider--slim">
														<a class="burnsSlideOut" target="_blank" id="id2009MemotoClinton1" data-title="Memo to Secretary Clinton, January 24, 2009" data-title-friendly="A New Strategy Toward Iran" href="https://ceipfiles.s3.amazonaws.com/pdf/back-channel/2009MemotoClinton1.pdf"
															data-video="False" data-idnumber="2009MemotoClinton1">
															<div class="col col-80">
																<div class="padded-less"><strong>Memo to Secretary Clinton, January 24, 2009 </strong>A New Strategy Toward Iran</div>
															</div>
															<div class="col col-15"></div>
														</a>
													</div>
												</div>
											</div>
											<div class="date-circle"><span>2008-<br>2014</span></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bottom-section">
						<div class="container">
							<a class="cta-button" href="https://www.penguinrandomhouse.com/books/561709/the-back-channel-by-william-j-burns/">order <em>The Back Channel</em> Now</a>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

onMounted(() => {
    // I need to catch any time an existing accordion or overlay is clicked, so it looks for all clicks on anything and acts if the className includes sortable ....
    document.onclick = function (e) {
        // console.log(e.target)
        if (e.target.className && e.target.className.includes('accordion-trigger')) {
            if (e.target.className.includes('active')) {
                e.target.classList.toggle('active');
                e.target.nextElementSibling.classList.toggle('active');
            } else {
                e.target.classList.toggle('active');
                e.target.nextElementSibling.classList.toggle('active');
            }
        }
    };
});
</script>

<style lang="scss" scoped>
$gutter: 30px;
$site-width: 1000px;

$gold: #a28556;
$black: #000;
$white: #fff;
$teal: #00a79d;
$light-gray: #f5f5f5;
$yellow: #fbb040;
$primary-dark-blue: #072a42;
$gold-medium: #998b7b;
$back-channel-gray-dark: #635a4f;
$back-channel-gray-medium: #ccc7c2;
$back-channel-gray-light: #e4e3dd;

$roman-normal-book: 'Apercu-Medium', Arial, sans-serif;
$roman-italic-book: 'Apercu-Medium-Italic', Arial, sans-serif;
$roman-normal-bold: 'Apercu-Bold', 'Arial Black', sans-serif;
$roman-italic-bold: 'Apercu-Bold-Italic', 'Arial Black', sans-serif;

%bold-sans-text {
    font-family: $roman-normal-bold;
    font-weight: 700;
}

%italic-sans-text {
    font-family: $roman-normal-book;
    font-style: italic;
}

//layout basics
.foreground {
    position: relative;
    margin: 0 auto;
    box-sizing: content-box;
    max-width: $site-width;
    padding-left: calc($gutter / 2);
    padding-right: calc($gutter / 2);
}

.section {
    display: block;
    clear: both;
    margin-top: $gutter;
    margin-bottom: $gutter;
}

.cols {
    clear: both;
	//use a .no-gutters class to undo these default styles
    margin-left: calc($gutter / 2) * -1;
    margin-right: calc($gutter / 2) * -1;

    &:before,
    &:after {
        content: ' ';
        display: table;
        height: 0;
        clear: both;
        font-size: 1px;
        line-height: 0px;
        visibility: hidden;
    }
}

.cols--equal-heights {
    flex-wrap: wrap;

    &,
    >.col {
        display: flex;
    }

    >.col {
        flex-direction: column;
    }

    .banded {
        flex: 1 0 auto;
    }
}

.cols {
    clear: both;
}

.col {
    float: left;

    .gutters {
        padding-left: calc($gutter / 2);
        padding-right: calc($gutter / 2);
    }
}

.col-80 {
    width: 80%;
}

.col-70 {
    width: 70%;
}

//two across
.col-50 {
    width: 50%;
}

.col-33 {
    width: 33%;
}

.col-30 {
    width: 30%;
}

//four across
.col-25 {
    width: 25%;
}

//five across
.col-20 {
    width: 20%;
}

.padded {
    padding: $gutter;
}

.gutter-right {
    margin-right: 30px;
}

.gutter-left {
    margin-left: 30px;
}

:deep(.accordion-content) {
    display: none;

    &.active {
        display: block;
        margin-top: 3rem;
    }
}

.section {
    font-family: $roman-normal-book;
    margin-bottom: 0;
    margin-top: 0;
    z-index: 3;
}
.hidden {display: none;}
// back channel archive
$back-channel-beige: #faf6ea;
$back-channel-red: #ef4237;
$back-channel-blue: #1f5081;
$back-channel-gray: #e5e5e5;

.back-channel {
    background-color: $back-channel-beige;
    background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/back-channel-background.png);
    background-position: left top;
    background-repeat: repeat-x;

    .watermark {
        background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/back-channel-archive-watermark.png);
        background-position: center -30px;
        background-repeat: no-repeat;
    }

	.padded-less {padding: 1em;}

    .zone-title {
		margin-top: 10rem;
        .section {
            margin-top: 0;
        }

        .col-left {
            border-right: 3px solid $back-channel-beige;
            padding-right: $gutter;
        }

        h1 {
            background-color: transparent;
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/back-channel-archive-top-titles-archive.png);
            background-position: center calc($gutter * 3);
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            height: 325px;
			width: 30rem;
			margin-left: 3rem;
        }

        h2 {
            background-color: transparent;
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/back-channel-top-titles.png);
            background-position: center 20px;
            background-repeat: no-repeat;
            background-size: auto 320px;
            display: block;
            height: 370px;
			width: 50rem;
        }
    }

    .zone-1 {
        padding-bottom: $gutter;

        .cta-button {
            background-color: $white;
            border: 3px solid $back-channel-red;
            border-radius: 0;
            color: $back-channel-red;
            display: inline-block;
            font-family: $roman-normal-bold;
            font-size: 1.1rem;
            letter-spacing: 0.1em;
            margin: calc($gutter * 2.5) auto calc($gutter * 1.75);
            padding: 10px 30px;
            text-align: center;
            text-transform: uppercase;

            &::after {
                display: none;
            }

            > span {
                display: block;
                font-family: $roman-normal-book;
                font-size: 0.8rem;
                margin-bottom: calc($gutter / 6);
            }

            > em {
                font-family: $roman-italic-bold;
                font-variant: normal;
            }

            &:hover {
                background-color: $back-channel-blue;
                border-color: $back-channel-blue;
                color: $white;
                text-decoration: none;
            }
        }

        .foreground {
            padding: 0;
            max-width: 1028px;
            width: 1028px;
        }

        .container {
            padding: 0 50px;
        }

        .top-section {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/back-channel-foreground_top.png);
            background-position: center -25px;
            padding-top: calc($gutter * 3);
            padding-bottom: $gutter;

            > .container {
                background-color: rgba(255, 255, 255, 0.7);
                display: block;
                font-family: $roman-normal-bold;
                margin: 0 auto;
                padding: calc($gutter / 2) $gutter;
                width: 950px;
            }

            .search-container {
                display: block;
                margin-bottom: calc($gutter * 2);
                position: relative;

                form {
                    float: left;
                    margin: 0 auto;
                    width: auto;

                    label {
                        display: none;
                    }

                    input[type="text"] {
                        border: 2px solid $back-channel-blue;
                        border-radius: 3px 0 0 3px;
                        box-shadow: none;
                        color: $back-channel-blue;
                        font-family: $roman-italic-bold;
                        font-size: 1.1rem;
                        width: 520px;
                    }

                    button {
                        border-radius: 0 3px 3px 0;
                        background-color: $back-channel-blue;
                        line-height: 1.4em;
                        top: 0;
                        z-index: 1;
                    }

                    &.form-search-results.clearfix.reset-function {
                        float: right;
                        left: -105px;
                        top: -39px;
                        position: relative;
                        width: calc($gutter * 2);

                        button {
                            background-color: $back-channel-gray-medium;
                            border-radius: 3px;
                            color: $back-channel-gray-dark;
                            z-index: 2;

                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .lower-section {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/back-channel-foreground_tile.png);
            background-repeat: repeat-y;
            margin-top: -60px;
        }

        .bottom-section {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/back-channel-foreground_bottom.png);
            background-position: bottom center;
            background-repeat: no-repeat;
            height: calc($gutter * 9);

            > .container {
                text-align: center;
            }
            .cta-button {
                margin-top: calc($gutter * 2);
            }
        }
    }

    /* The actual timeline (the vertical ruler) */
    .timeline {
        position: relative;
        max-width: 920px;
    }

    /* The actual timeline (the vertical ruler) */
    .timeline::after {
        box-shadow: 2px 0 0 0 $back-channel-beige;
        content: "";
        position: absolute;
        width: 6px;
        background-color: $back-channel-red;
        top: 0;
        bottom: 0;
        // margin-bottom: 300px;
        margin-left: 27px;
        margin-top: 30px;
    }

    .chapter-boxes > .section {
        margin-bottom: 0;
    }

    .chapter-box {
        font-family: $roman-normal-book;
        height: 350px;
        position: relative;

        &.section {
            margin-bottom: 0;
            margin-top: calc($gutter * 2);
        }

        .chapter-info {
			border-left: $back-channel-red 5px solid;
            background-repeat: no-repeat;
            background-size: auto 100%;
            -webkit-box-shadow: inset 0 -200px 200px -70px $black;
            box-shadow: inset 0 -200px 200px -70px $black;
            // display: table;
            float: left;
            height: 100%;
            margin-left: 3%;
            position: relative;
            width: 40%;
        }

        .info-container {
            color: $white;
            padding: $gutter $gutter $gutter calc($gutter * 1.5);
            position: absolute;
            bottom: 0;
            vertical-align: bottom;

            h4 {
                color: $white;
                line-height: 1.1em;
                padding-bottom: calc($gutter / 3);
            }

            p {
                font-family: $roman-normal-bold;
                font-size: 1.3rem;
                line-height: normal;
                margin-bottom: 0;
            }
        }

        &.chapter-box1 .chapter-info {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/chapter-image_1.jpg);
        }
        &.chapter-box2 .chapter-info {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/chapter-image_2.jpg);
        }
        &.chapter-box3 .chapter-info {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/chapter-image_3.jpg);
        }
        &.chapter-box4 .chapter-info {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/chapter-image_4.jpg);
        }
        &.chapter-box5 .chapter-info {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/chapter-image_5.jpg);
        }
        &.chapter-box6 .chapter-info {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/chapter-image_6.jpg);
        }
        &.chapter-box7 .chapter-info {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/chapter-image_7.jpg);
        }
        &.chapter-box8 .chapter-info {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/chapter-image_8.jpg);
        }
        &.chapter-box9 .chapter-info {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/chapter-image_9.jpg);
        }

        .button {
            background-color: $back-channel-red;
			font-size: 1rem;
			text-transform: uppercase;
			white-space: nowrap;
			color: white;
			padding: 1em;
			border-radius: 1em;
        }

        .date-circle {
            background-color: $back-channel-red;
            border: 4px solid $back-channel-red;
            border-radius: 50%;
            box-shadow: 0 0 0 2px $back-channel-beige;
            color: $white;
            content: "";
            display: table;
            font-family: $roman-normal-bold;
            font-size: 0.9rem;
            height: 60px;
            line-height: 0.9em;
            position: relative;
            text-align: center;
            top: -92%;
            width: 60px;
            z-index: 1;

            span {
                display: table-cell;
                vertical-align: middle;
                word-wrap: break-word;
            }
        }

        .inner-box {
            height: 350px;
        }

        .document-list {
            background-color: rgba(255, 255, 255, 0.6);
            border-bottom: 5px solid $back-channel-gray-light;
            border-top: 5px solid $back-channel-gray-light;
            display: inline-block;
            float: right;
            height: 100%;
            overflow-x: hidden;
            overflow-y: scroll;
            width: 57%;

            position: relative;
            z-index: 90;

            .doc-container {
                font-size: 1.2rem;
                line-height: 1.1em;
                padding-left: calc($gutter / 2);

                &.divider {
                    margin-bottom: 0;
                    padding-bottom: 0;

                    &:nth-last-child(1) {
                        border-bottom: 0;
                    }
                }

				.padded-less {
					font-size: 1.5rem;
				}

                strong {
                    display: block;
                    font-family: $roman-normal-bold;
                    font-size: 1rem;
                    font-weight: normal;
                    line-height: 1.1em;
                    padding-bottom: calc($gutter / 3);
                    text-transform: uppercase;
                    width: 70%;
                }

                &:hover {
                    background-color: $back-channel-beige;

                    a {
                        color: $back-channel-red;
                    }
                }

                .col-15 {
                    padding-top: calc(calc($gutter / 2));
                }
            }

            a {
                cursor: pointer;
            }
        }
    }

    @include media-query(mobile) {
        .zone-title {
            h1 {
                background-position: center 90px;
                background-size: 40vw auto;
                height: 250px;
            }

            h2 {
                background-position: right 20px;
                background-size: 220px auto;
                height: 250px;
            }
        }

        .zone-1 {
            padding-bottom: 0;

            .container {
                padding: 0 $gutter;
            }

            .foreground {
                width: 90vw;
            }
            .top-section,
            .lower-section,
            .bottom-section {
                background-size: 100% auto;
            }

            .top-section > .container {
                width: 82vw;
            }

            .top-section {
                .search-container {
                    padding: 0;
                }
                form {
                    &.form-search-results.clearfix.reset-function {
                        left: 0;
                        margin-left: 500px;
                    }
                }
            }

            .lower-section {
                > .container {
                    width: 90vw;
                    padding-bottom: calc($gutter * 4);
                }
            }
        }

        .timeline {
            max-width: 80vw;
        }

        .chapter-box {
            height: 450px;

            // &.chapter-box1 {
            // 	height: 300px;

            // 	.date-circle {top: -76%;}
            // }
            // &.section {border-right: 1px solid $back-channel-gray;}

            .inner-box {
                height: 200px;

                .chapter-info {
                    float: none;
                    margin-left: 5%;
                    width: auto;
                }
            }

            .document-list {
                border-top: none;
                float: none;
                margin-left: 5%;
                width: 76vw;
            }

            .date-circle {
                top: -51%;
            }

            .chapter-info {
                background-position: center -65px;
                background-size: 100% auto;
            }
        }

        .slide-in-detail {
            .close-button {
                padding-bottom: calc($gutter * 2);
            }
            .panel-container .video {
                bottom: 90px;
            }
        }
    }

    @include media-query(phone) {
        background-size: auto 400px;

        .watermark {
            background-image: url(https://ceipimg.s3.amazonaws.com/static/back-channel/back-channel-watermark.png);
            background-size: auto 700px;
        }
        .zone-title {
            .col-left {
                border-right: none;
                padding-right: 0;
            }
            h1 {
                background-position: center 20px;
                background-size: 50vw auto;
                height: 100px;
            }
            h2 {
                background-position: center 20px;
            }
        }

        .zone-1 {
            .container {
                padding: 0 25px;
            }
            .top-section {
                background-position: center top;
                .search-container {
                    form input[type="text"] {
                        width: 250px;
                    }
                    form button {
                        top: 10px;
                    }
                    form.form-search-results.clearfix.reset-function {
                        left: -75px;
                    }
                }
            }

            .chapter-box {
                &.chapter-box1 {
                    height: 450px;
                    .date-circle {
                        top: -51%;
                    }
                }

                .button {
                    display: inline-block;
                    margin-bottom: calc(calc($gutter / 2));
                }

                .padded-less {
                    padding: 5px;
                }
            }

            .lower-section {
                .foreground {
                    padding-top: $gutter;
                }

                > .container {
                    strong {
                        font-size: 1rem;
                        padding-top: calc(calc($gutter / 2));
                    }

                    .quotes {
                        p {
                            font-size: 1.1rem;

                            span {
                                font-size: 0.9rem;
                            }
                        }
                    }

                    .info-container p {
                        display: none;
                    }
                }
            }

            .bottom-section {
                height: auto;

                > .container {
                    padding-top: $gutter;
                }
            }
        }

        .timeline::after {
            margin-left: 6px;
            top: -15px;
        }

        .slide-in-detail {
            .panel-container {
                .title,
                .description {
                    width: 80%;
                }
                .title {
                    font-size: 1.7rem;
                }
                .description {
                    margin-bottom: calc(calc($gutter / 2));
                    padding-bottom: calc(calc($gutter / 2));
                }
                #slideDocTextEmbed,
                #slidePDFURL {
                    display: none;
                }
                .embed {
                    display: none;
                    // margin-left: 0;
                    // margin-top: $gutter;
                    // width: 80%;
                }
                .video {
                    bottom: 0;
                    display: block;
                    margin-top: calc(calc($gutter / 2));
                    position: relative;
                    width: 80%;
                }
                .center {
                    text-align: left;
                }
            }
        }
    }
}
</style>